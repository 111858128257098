import React from "react"
import General2024 from "./candidate-views/2024-general"
const CandidatePage = () => {
 return (
  <>
   <General2024 />
  </>
 )
}

export default CandidatePage
